import axios from 'axios'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'

const Pricing = (props) => {
  const [pricingType, togglePricingType] = useState('yearly');
  const [selectedPackage, selectPackage] = useState(null);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (values) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/payments`, {
        ...values,
        duration: pricingType,
        plan: selectedPackage,
      })
      .then(({ data }) => {
        window.location.href = data.url;
      });
  };

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={!!selectedPackage}>
        <ModalHeader toggle={() => selectPackage(null)}>
          Pakiet{' '}
          {selectedPackage === 'professional' ? 'Profesjonaly' : 'Startowy'} w
          rozliczeniu {pricingType === 'yearly' ? 'rocznym' : 'miesięcznym'}
        </ModalHeader>
        <ModalBody>
          <p>
            <small>
              Uzupełnij poprawnie poniższy formularz. Po dokonaniu płatności za
              pomocą platformy <strong>przelewy24.pl</strong>, otrzymasz email z
              danymi dostępowymi do swojego konta w aplikacji{' '}
              <strong>Archipanel</strong>.
            </small>
          </p>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup className="mb-3">
              <FormGroup className="mb-3">
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      invalid={errors.email}
                      placeholder="Adres email"
                      type="text"
                      {...field}
                    />
                  )}
                />
                {errors.email && (
                  <FormFeedback>To pole jest wymagane</FormFeedback>
                )}
              </FormGroup>
              <Controller
                name="firstName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    invalid={errors.firstName}
                    placeholder="Imię"
                    type="text"
                    {...field}
                  />
                )}
              />
              {errors.firstName && (
                <FormFeedback>To pole jest wymagane</FormFeedback>
              )}
            </FormGroup>
            <FormGroup className="mb-3">
              <Controller
                name="lastName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    invalid={errors.lastName}
                    placeholder="Nazwisko"
                    type="text"
                    {...field}
                  />
                )}
              />
              {errors.lastName && (
                <FormFeedback>To pole jest wymagane</FormFeedback>
              )}
            </FormGroup>
            <FormGroup className="mb-3">
              <Controller
                name="company"
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder="Nazwa firmy (opcjonalnie)"
                    type="text"
                    {...field}
                  />
                )}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Controller
                name="nip"
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder="NIP (opcjonalnie)"
                    type="text"
                    {...field}
                  />
                )}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Controller
                name="address"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    invalid={errors.address}
                    placeholder="Miasto, kod pocztowy"
                    type="text"
                    {...field}
                  />
                )}
              />
              {errors.address && (
                <FormFeedback>To pole jest wymagane</FormFeedback>
              )}
            </FormGroup>
            <FormGroup className="mb-3">
              <Controller
                name="address2"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    invalid={errors.address2}
                    placeholder="Ulica i numer domu"
                    type="text"
                    {...field}
                  />
                )}
              />
              {errors.address2 && (
                <FormFeedback>To pole jest wymagane</FormFeedback>
              )}
            </FormGroup>
            <FormGroup check className="mb-3">
              <Controller
                name="terms"
                control={control}
                rules={{ validate: (v) => v === true }}
                render={({ field }) => (
                  <>
                    <Input
                      invalid={errors.terms && errors.terms.type === 'validate'}
                      id="terms"
                      type="checkbox"
                      {...field}
                    />
                    <Label for="terms" check>
                      Zgadzam się na przetwarzanie moich danych w celach
                      rejestracji i księgowania płatności, zgodnie z{' '}
                      <Link to="/terms-and-conditions">
                        regulaminem Archipanel.pl
                      </Link>
                      .
                    </Label>
                  </>
                )}
              />
              {errors.terms && errors.terms.type === 'validate' && (
                <FormFeedback>To pole jest wymagane</FormFeedback>
              )}
            </FormGroup>
            <FormGroup check className="mb-3">
              <Controller
                name="marketing"
                control={control}
                render={({ field }) => (
                  <>
                    <Input id="marketing" type="checkbox" {...field} />
                    <Label for="marketing" check>
                      Zgadzam się na wysyłanie drogą mailową informacji o
                      charakterze handlowym.
                    </Label>
                  </>
                )}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={() => selectPackage(null)}>
            Anuluj
          </Button>
          <Button color="primary" onClick={handleSubmit(onSubmit)}>
            Zamów i zapłać
          </Button>{' '}
        </ModalFooter>
      </Modal>
      <ul className="flex-column flex-sm-row rounded nav nav-pills nav-justified">
        <li className="nav-item">
          <button
            onClick={() => togglePricingType('yearly')}
            className={`rounded nav-link ${
              pricingType === 'yearly' && 'active'
            }`}
          >
            Rozliczenie roczne
          </button>
        </li>
        <li className="nav-item">
          <button
            onClick={() => togglePricingType('monthly')}
            className={`rounded nav-link ${
              pricingType === 'monthly' && ' active'
            }`}
          >
            Rozliczenie miesięczne
          </button>
        </li>
      </ul>
      {props.pricings.map((pricing, key) => (
        <Col
          name="pricing"
          lg="4"
          md="6"
          xs="12"
          key={key}
          className="mt-4 pt-2"
        >
          <Card
            name="pricingbox"
            className={
              pricing.isActive
                ? 'pricing-rates business-rate shadow bg-white border-0 rounded'
                : 'pricing-rates business-rate bg-light shadow border-0 rounded'
            }
          >
            {pricing.isActive && (
              <div className="ribbon ribbon-right ribbon-warning overflow-hidden">
                <span className="text-center d-block shadow small h6">
                  Best
                </span>
              </div>
            )}
            <CardBody>
              <h6
                className={
                  pricing.isActive
                    ? 'title fw-bold text-uppercase text-primary mb-4'
                    : 'title fw-bold text-uppercase text-primary mb-4'
                }
              >
                {pricing.title}
              </h6>
              {pricing.price > 0 && (
                <>
                  <div className="d-flex mb-4" name="pricingsection">
                    <span className="price h1 mb-0">
                      {pricingType === 'yearly'
                        ? pricing.price
                        : pricing.monthlyPrice}
                    </span>
                    <span className="h4 mb-0 mt-2">zł netto</span>
                    <span className="h4 align-self-end mb-1">/m-c</span>
                  </div>

                  <div className="d-flex mb-4" name="pricingsection">
                    Do zapłaty:{' '}
                    {pricingType === 'monthly' ? (
                      <>{pricing.monthlyPrice * 1.23} zł (z VAT)</>
                    ) : (
                      <>
                        &nbsp;
                        <span
                          style={{
                            textDecoration: 'line-through',
                            color: '#aaa',
                          }}
                        >
                          {pricing.monthlyPrice * 12 * 1.23} zł
                        </span>
                        &nbsp;
                        {pricing.price * 12 * 1.23} zł (z VAT)
                      </>
                    )}
                  </div>
                </>
              )}
              {pricing.price === 0 && (
                <div className="d-flex mb-4" name="pricingsection">
                  <span className="h4 mb-0 mt-2">
                    Skontaktuj się z nami, by ustalić szczegóły
                  </span>
                </div>
              )}

              <ul className="list-unstyled mb-0 ps-0">
                {pricing.features.map((feature, key) => (
                  <li key={key} className="h6 text-muted mb-0">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    {feature.title}
                  </li>
                ))}
              </ul>

              {pricing.to ? (
                <Link to={pricing.to} className="btn btn-primary mt-4">
                  {pricing.buttonText}
                </Link>
              ) : (
                <Button
                  onClick={() => selectPackage(pricing.titleKey)}
                  className="btn btn-primary mt-4"
                >
                  {pricing.buttonText}
                </Button>
              )}
            </CardBody>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  );
};

export default Pricing;
