import React from 'react'

import Pricing from './components/Shared/PricingBox'
import IndexClassicApp from './pages/Classic Application'

const PageError = React.lazy(() => import('./pages/Pages/Special/PageError'));
const PageThankYou = React.lazy(() =>
  import('./pages/Pages/Special/PageThankYou'),
);
const PageEarlyAccess = React.lazy(() =>
  import('./pages/Pages/Special/PageEarlyAccess'),
);
const PageEmailVerification = React.lazy(() =>
  import('./pages/Pages/Special/PageEmailVerification'),
);
const IndexclassicSaas = React.lazy(() => import('./pages/Classic Saas/index'));
const PageServices = React.lazy(() => import('./pages/Pages/PageServices'));
const Contact = React.lazy(() => import('./pages/Pages/Contact/Contact'));
const BlogList = React.lazy(() => import('./pages/Pages/Blog/PageBlogList'));
const PageBlog = React.lazy(() =>
  import('./pages/Pages/Blog/PageBlogDetailTwo'),
);
const PrivacyPolicy = React.lazy(() =>
  import('./pages/Pages/Utility/PagePrivacy'),
);
const TermsAndConditions = React.lazy(() =>
  import('./pages/Pages/Utility/PageTerms'),
);

const routes = [
  { path: '/features', component: PageServices, isTopbarDark: true },
  {
    path: '/terms-and-conditions',
    component: TermsAndConditions,
    isTopbarDark: true,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
    isTopbarDark: true,
  },
  {
    path: '/contact',
    component: Contact,
    isTopbarDark: true,
  },
  {
    path: '/blog',
    component: BlogList,
    isTopbarDark: true,
  },
  {
    path: '/blog/:slug',
    component: PageBlog,
    isTopbarDark: true,
  },
  {
    path: '/',
    component: IndexclassicSaas,
    isTopbarDark: true,
  },
  { path: '/thank-you', component: PageThankYou, isTopbarDark: true },
  {
    path: '/email-verification',
    component: PageEmailVerification,
    isTopbarDark: true,
  },
  { path: '/early-access', component: PageEarlyAccess, isTopbarDark: true },

  // PAYMENT TESTING SUBPAGE
  {
    path: '/t231',
    component: () => {
      let p = prompt('');

      return (
        p === 'doom2' && (
          <Pricing
            pricings={[
              {
                id: 1,
                title: 'Startowy',
                titleKey: 'starter',
                price: 29,
                monthlyPrice: 35,
                buttonText: 'Załóż konto',
                to: false,
                btnLink: '',
                isActive: false,
                features: [{ title: 'Liczba aktywnych projektów: 2' }],
              },
            ]}
          />
        )
      );
    },
    isTopbarDark: true,
  },
  { path: '/', component: IndexClassicApp, isWithoutLayout: true, exact: true },
  { component: PageError, isWithoutLayout: true, exact: false },
];

export default routes;
